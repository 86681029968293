var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"modelsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"MonitorIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Network specifications")])],1),_c('div',[_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Network","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"network_ip","rules":"required|max:255","vid":"network_ip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"name","placeholder":"Select network","options":_vm.networks,"is-open":"","open":"","return-object":"","filled":"","searchable":true},on:{"input":_vm.onNetworkChange,"search":_vm.onSearchNetwork},model:{value:(_vm.network),callback:function ($$v) {_vm.network=$$v},expression:"network"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.network)?_c('p',{staticClass:"input-under-text"},[_vm._v(" Vlan Interface Address: "+_vm._s(_vm.network.interface_ip)+" ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Name","label-class":"label-class","disabled":""}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:255","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter unique name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Network Address","label-class":"label-class","disabled":""}},[_c('validation-provider',{attrs:{"name":"Network Address","vid":"dhcp_address","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"000.000.0.0","autocomplete":"off","disabled":true,"onchange":function (value) { return _vm.form.dhcp_address = value; },"model":_vm.form.dhcp_address,"inputs-id":"dhcp_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Subnet Mask","label-class":"label-class","disabled":""}},[_c('validation-provider',{attrs:{"name":"Subnet Mask","vid":"subnet_mask","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"000.000.0.0","autocomplete":"off","disabled":true,"onchange":function (value) { return _vm.form.subnet_mask = value; },"model":_vm.form.subnet_mask,"inputs-id":"subnet_mask"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Gateway IP","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Gateway IP","vid":"gateway_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter gateway IP address","autocomplete":"off","onchange":function (value) { return _vm.form.gateway_ip = value; },"model":_vm.form.gateway_ip,"inputs-id":"gateway_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"WiFi Controller IP","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"WiFi Controller IP","rules":"max:255","vid":"wifi_controller_ip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter the WiFi Controller IP","autocomplete":"off","onchange":function (value) { return _vm.form.wifi_controller_ip = value; },"model":_vm.form.wifi_controller_ip,"inputs-id":"wifi_controller_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"DNS Server IP","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"DNS Server IP","vid":"dns1_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter DNS server IP address","autocomplete":"off","onchange":function (value) { return _vm.form.dns1_ip = value; },"model":_vm.form.dns1_ip,"inputs-id":"dns1_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"DNS Server IP #2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"DNS Server IP #2","vid":"dns2_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter second DNS server IP address","autocomplete":"off","onchange":function (value) { return _vm.form.dns2_ip = value; },"model":_vm.form.dns2_ip,"inputs-id":"dns2_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"NTP Server IP","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"NTP Server IP","vid":"ntp1_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter NTP server IP address","autocomplete":"off","onchange":function (value) { return _vm.form.ntp1_ip = value; },"model":_vm.form.ntp1_ip,"inputs-id":"ntp1_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"NTP Server IP #2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"NTP Server IP #2","vid":"ntp2_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter second NTP server IP address","autocomplete":"off","onchange":function (value) { return _vm.form.ntp2_ip = value; },"model":_vm.form.ntp2_ip,"inputs-id":"ntp2_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"IP Pool Start","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IP Pool Start","vid":"ip_pool_start","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter IP pool start address","autocomplete":"off","onchange":function (value) { return _vm.form.ip_pool_start = value; },"model":_vm.form.ip_pool_start,"inputs-id":"ip_pool_start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"IP Pool End","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IP Pool End","vid":"ip_pool_end","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter IP pool end address","autocomplete":"off","onchange":function (value) { return _vm.form.ip_pool_end = value; },"model":_vm.form.ip_pool_end,"inputs-id":"ip_pool_end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('h6',{staticClass:"mt-1 mb-1"},[_vm._v(" Lease Time ")]),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Days","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Lease Time Days","vid":"lease_time","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"title","placeholder":"Select days amount","options":Array.from(Array(32).keys()).map(function (el) { return el.toString(); })},model:{value:(_vm.form.lease_time_days),callback:function ($$v) {_vm.$set(_vm.form, "lease_time_days", $$v)},expression:"form.lease_time_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('span',{staticClass:"date-double-dots"},[_vm._v(":")]),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Hours","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Lease Time Hours","vid":"lease_time_hours","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"title","options":Array.from(Array(24).keys()).map(function (el) { return el.toString(); }),"placeholder":"Select hours amount"},model:{value:(_vm.form.lease_time_hours),callback:function ($$v) {_vm.$set(_vm.form, "lease_time_hours", $$v)},expression:"form.lease_time_hours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('span',{staticClass:"date-double-dots"},[_vm._v(":")]),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Minutes","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Lease Time Minutes","vid":"lease_time_minutes","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Select minutes","label":"title","options":Array.from(Array(60).keys()).map(function (el) { return el.toString(); })},model:{value:(_vm.form.lease_time_minutes),callback:function ($$v) {_vm.$set(_vm.form, "lease_time_minutes", $$v)},expression:"form.lease_time_minutes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container mt-1"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Domain Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Domain Name","rules":"max:255","vid":"domain_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter domain name","autocomplete":"off"},model:{value:(_vm.form.domain_name),callback:function ($$v) {_vm.$set(_vm.form, "domain_name", $$v)},expression:"form.domain_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])])]),_c('b-col',{staticClass:"text-right buttons-container__left",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","ok-variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('b-button-request',{attrs:{"text":"Update","request":_vm.validationForm}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }