<template>
  <div
    :class="classContainer"
  >
    <b-button
      :variant="variant"
      :disabled="isLoading || disabled"
      :class="`d-flex align-items-center ${classContainer}`"
      :type="type"
      @click.prevent="handleClick"
    >
      <feather-icon
        v-if="icon && !isLoading"
        :icon="icon"
        size="16"
        class="mr-50"
      />
      <b-spinner
        v-if="isLoading"
        variant="light"
        label="Spinning"
        small
        class="mr-50"
      />
      {{ text }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BSpinner,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: true,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: 'submit',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    classContainer: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  watch: {
  },
  methods: {
    async handleClick() {
      try {
        this.isLoading = true
        await this.request()
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
