<template>
  <div
    class="ip-container"
    :class="{'disabled-ip': disabled, 'errors-ip': errors.length > 0}"
    @click="selectFirstEmpty"
  >
    <span
      v-if="disabled && !model"
      class="placeholder-ip"
    >
      {{ placeholder }}
    </span>
    <b-form-input
      :id="`${inputsId}-ip1`"
      :value="IP1"
      :placeholder="' '"
      :disabled="disabled"
      class="ip-input"
      :maxlength="3"
      @input="(value) => ipWrites(1, value)"
      @keyup.right.prevent="nextIP(1)"
      @focus="onFocusInput(1)"
    />
    <span v-if="!disabled || model">.</span>
    <b-form-input
      :id="`${inputsId}-ip2`"
      :value="IP2"
      :placeholder="' '"
      :disabled="disabled"
      class="ip-input"
      :maxlength="3"
      @input="(value) => ipWrites(2, value)"
      @keyup.right="nextIP(2)"
      @keyup.left="prevIP(2)"
      @focus="onFocusInput(2)"
      @keydown="(e) => handleBackspace(2, e)"
    />
    <span v-if="!disabled || model">.</span>
    <b-form-input
      :id="`${inputsId}-ip3`"
      :value="IP3"
      :placeholder="' '"
      :disabled="disabled"
      class="ip-input"
      :maxlength="3"
      @input="(value) => ipWrites(3, value)"
      @keyup.right="nextIP(3)"
      @keyup.left="prevIP(3)"
      @focus="onFocusInput(3)"
      @keydown="(e) => handleBackspace(3, e)"
    />
    <span v-if="!disabled || model">.</span>
    <b-form-input
      :id="`${inputsId}-ip4`"
      :value="IP4"
      :placeholder="' '"
      :disabled="disabled"
      class="ip-input"
      :maxlength="3"
      @input="(value) => ipWrites(4, value)"
      @keyup.left="prevIP(4)"
      @focus="onFocusInput(4)"
      @keydown="(e) => handleBackspace(4, e)"
    />
    <span
      v-if="isFullAvailable && IP4"
      class="full-available"
    >/</span>
    <b-form-input
      v-if="isFullAvailable && IP4"
      :id="`${inputsId}-ip5`"
      :value="model.split('/')[1]"
      :placeholder="' '"
      :disabled="disabled"
      class="ip-input"
      :maxlength="2"
      @input="(value) => ipWrites(5, value)"
    />
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
  },
  props: {
    model: {
      type: String,
      required: true,
      default: '',
    },
    isFullAvailable: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array || String,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onchange: {
      type: Function,
      default: () => {},
    },
    inputsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IP1: '',
      IP2: '',
      IP3: '',
      IP4: '',
      IP5: '',
    }
  },
  watch: {
    model() {
      const shortModel = this.model.split('/')[0]
      const ip = shortModel.split('.')
      this.IP1 = ip[0] || ''
      this.IP2 = ip[1] || ''
      this.IP3 = ip[2] || ''
      this.IP4 = ip[3] || ''

      if (this.isFullAvailable) {
        const mask = this.model.split('/')[1] || ''
        this.IP5 = mask
      }
    },
  },
  methods: {
    onFocusInput(id) {
      const input = document.getElementById(`${this.inputsId}-ip${id}`)
      input.setSelectionRange(input.value.length, input.value.length)
    },
    ipWrites(id, val) {
      if (val && !+val && val !== '0') {
        console.log('value in input', document.getElementById(`${this.inputsId}-ip${id}`).value)
        setTimeout(() => {
          const input = document.getElementById(`${this.inputsId}-ip${id}`)
          input.value = input.value.substring(0, input.value.length - 1)
        }, 20)
        return
      }
      if (val.includes('.')) {
        setTimeout(() => {
          const input = document.getElementById(`${this.inputsId}-ip${id}`)
          input.value = input.value.replace('.', '')
          if (id < 4) {
            document.getElementById(`${this.inputsId}-ip${id + 1}`).focus()
          }
        }, 20)
        return
      }
      switch (id) {
        case 1:
          this.IP1 = val
          if (val.length === 3 || val.includes('.')) {
            if (val > 255) {
              this.IP1 = 255
            }
            document.getElementById(`${this.inputsId}-ip2`).focus()
          }
          if (val.length === 0 && val === '') {
            document.getElementById(`${this.inputsId}-ip1`).focus()
          }
          break
        case 2:
          this.IP2 = val
          if (val.length === 3) {
            if (val > 255) {
              this.IP2 = 255
            }
            document.getElementById(`${this.inputsId}-ip3`).focus()
          }
          if (val.length === 0 && val === '') {
            document.getElementById(`${this.inputsId}-ip1`).focus()
          }
          break
        case 3:
          this.IP3 = val
          if (val.length === 3) {
            if (val > 255) {
              this.IP3 = 255
            }
            document.getElementById(`${this.inputsId}-ip4`).focus()
          }
          if (val.length === 0 && val === '') {
            document.getElementById(`${this.inputsId}-ip2`).focus()
          }
          break
        case 4:
          this.IP4 = val
          if (val > 255) {
            this.IP4 = 255
          }
          if (val.length === 0 && val === '') {
            document.getElementById(`${this.inputsId}-ip3`).focus()
          }
          break
        case 5:
          this.IP5 = val
          break
        default:
          break
      }

      const formattedIP = `${this.IP1}${this.IP2 && `.${this.IP2}`}${this.IP3 && `.${this.IP3}`}${this.IP4 && `.${this.IP4}${this.IP5 && `/${this.IP5}`}`}`
      this.onchange(formattedIP)
    },
    nextIP(id) {
      switch (id) {
        case 1:
          document.getElementById(`${this.inputsId}-ip2`).focus()
          break
        case 2:
          document.getElementById(`${this.inputsId}-ip3`).focus()
          break
        case 3:
          document.getElementById(`${this.inputsId}-ip4`).focus()
          break
        case 4:
          break
        default:
          break
      }
    },
    prevIP(id) {
      switch (id) {
        case 1:
          break
        case 2:
          document.getElementById(`${this.inputsId}-ip1`).focus()
          break
        case 3:
          document.getElementById(`${this.inputsId}-ip2`).focus()
          break
        case 4:
          document.getElementById(`${this.inputsId}-ip3`).focus()
          break
        default:
          break
      }
    },
    selectFirstEmpty(e) {
      if (e.target.type === 'text') {
        return
      }
      if (this.IP1 === '') {
        document.getElementById(`${this.inputsId}-ip1`).focus()
      } else if (this.IP2 === '') {
        document.getElementById(`${this.inputsId}-ip2`).focus()
      } else if (this.IP3 === '') {
        document.getElementById(`${this.inputsId}-ip3`).focus()
      } else {
        document.getElementById(`${this.inputsId}-ip4`).focus()
      }
    },
    handleBackspace(id, e) {
      const isEmpty = e.srcElement.value.length === 0
      if ((e.key === 'Backspace' || e.key === 'Delete') && id !== 1 && isEmpty) {
        document.getElementById(`${this.inputsId}-ip${id - 1}`).focus()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
